const categoryChoices = [
  {
    value: 1,
    name: "Strength",
  },
  {
    value: 2,
    name: "Cardio",
  },
  {
    value: 3,
    name: "Wellness",
  },
  {
    value: 4,
    name: "USP",
  },
];

export default categoryChoices;
